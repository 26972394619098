import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import BlogItem from "./Blog";
import ContactUsItem from "./ContactUs";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityFooter {
        id
        contactDetails {
          title
          addressIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 40
                width: 40
                placeholder: BLURRED
              )
            }
          }
          address
          emailIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 40
                width: 40
                placeholder: BLURRED
              )
            }
          }
          email
          phoneIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 40
                width: 40
                placeholder: BLURRED
              )
            }
          }
          phone
        }
        socialLinks {
          footerLogo {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 80
                width: 80
                placeholder: BLURRED
              )
            }
          }
          facebookIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 32
                width: 32
                placeholder: BLURRED
              )
            }
          }
          facebookUrl
          twitterIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 32
                width: 32
                placeholder: BLURRED
              )
            }
          }
          twitterUrl
          instagramIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 32
                width: 32
                placeholder: BLURRED
              )
            }
          }
          instagramUrl
          youtubeIcon {
            asset {
              gatsbyImageData(
                formats: WEBP
                height: 32
                width: 32
                placeholder: BLURRED
              )
            }
          }
          youtubeUrl
        }
      }
    }
  `);
  const footerData = data.sanityFooter;
  return (
    <footer className="block bg-[#18191d] text-white py-[100px]">
      <div className="custom_container">
        <div className="row">
          <ContactUsItem data={footerData.contactDetails} />
          <BlogItem />
          <div className="col lg:col-lg-4">
            <div className="">
              {/* Logo */}
              <div className="mb-[50px]">
                <GatsbyImage
                  image={
                    footerData.socialLinks.footerLogo.asset.gatsbyImageData
                  }
                  alt="Logo"
                  loading="lazy"
                  width={50}
                  objectFit="contain"
                />
              </div>
              {/* Social Links */}
              <div className="flex">
                <a
                  href={footerData.socialLinks.facebookUrl}
                  className="w-[60px] h-[60px] text-center rounded-full border border-solid border-white/5 text-[13px]
                  text-white mr-[10px] flex items-center justify-center"
                >
                  <GatsbyImage
                    image={
                      footerData.socialLinks.facebookIcon.asset.gatsbyImageData
                    }
                    alt="Facebook"
                    loading="lazy"
                    objectFit="contain"
                    className="w-4 h-4 object-contain"
                  />
                </a>
                <a
                  href={footerData.socialLinks.twitterUrl}
                  className="w-[60px] h-[60px] text-center rounded-full border border-solid border-white/5 text-[13px]
                  text-white mr-[10px] flex items-center justify-center"
                >
                  <GatsbyImage
                    image={
                      footerData.socialLinks.twitterIcon.asset.gatsbyImageData
                    }
                    alt="Twitter"
                    loading="lazy"
                    objectFit="contain"
                    className="w-4 h-4 object-contain"
                  />
                </a>
                <a
                  href={footerData.socialLinks.instagramUrl}
                  className="w-[60px] h-[60px] text-center rounded-full border border-solid border-white/5 text-[13px]
                  text-white mr-[10px] flex items-center justify-center"
                >
                  <GatsbyImage
                    image={
                      footerData.socialLinks.instagramIcon.asset.gatsbyImageData
                    }
                    alt="Instagram"
                    loading="lazy"
                    objectFit="contain"
                    className="w-4 h-4 object-contain"
                  />
                </a>
                <a
                  href={footerData.socialLinks.youtubeUrl}
                  className="w-[60px] h-[60px] text-center rounded-full border border-solid border-white/5 text-[13px]
                  text-white mr-[10px] flex items-center justify-center"
                >
                  <GatsbyImage
                    image={
                      footerData.socialLinks.youtubeIcon.asset.gatsbyImageData
                    }
                    alt="Youtube"
                    loading="lazy"
                    objectFit="contain"
                    className="w-4 h-4 object-contain"
                  />
                </a>
              </div>
              {/* Copyright */}
              <div className="mt-[30px]">
                <p className="text-[13px] font-light uppercase tracking-[3px] text-[#a4a7b1]">
                  ©2023, CIVILIZED SAVAGE
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
