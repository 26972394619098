import React, { useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";
import { Link as LinkScroll } from "react-scroll";

function CustomLink({ to, children, handleClick }) {
  if (typeof window !== "undefined" && window.location.pathname === "/") {
    // If the user is on the home page, render a React Scroll Link
    return (
      <LinkScroll
        to={to}
        className="block text-xs font-medium text-white lg:py-[15px] lg:px-[7px] my-[10px] mx-auto lg:mx-[15px] cursor-pointer"
        smooth
        duration="500"
        onClick={handleClick}
      >
        {children}
      </LinkScroll>
    );
  } else {
    // If the user is on a subpage, render a Gatsby Link
    return (
      <GatsbyLink
        className="block text-xs font-medium text-white lg:py-[15px] lg:px-[7px] my-[10px] mx-auto lg:mx-[15px] cursor-pointer"
        to={`/#${to}`}
        onClick={handleClick}
      >
        {children}
      </GatsbyLink>
    );
  }
}

const Navbar = () => {
  const { sanityHeader } = useStaticQuery(graphql`
    {
      sanityHeader(id: { eq: "-9b671c63-cf9f-5049-9bb0-ace611c6e393" }) {
        logo {
          alt
          image {
            asset {
              gatsbyImageData(
                width: 80
                height: 80
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
        headerLinks {
          linkTitle
          wantToNavigate
          sectionId
          slug {
            current
          }
        }
      }
    }
  `);

  const [mobileNav, setMobileNav] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    // Add an event listener for the scroll event
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Only run the effect once

  function handleScroll() {
    // Get the current scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // If the scroll position is greater than the specified number of pixels
    if (scrollTop > 100) {
      // Add the nav-scroll class to the container
      containerRef.current.classList.add("nav-scroll");
    } else {
      // Otherwise, remove the nav-scroll class from the container
      containerRef.current.classList.remove("nav-scroll");
    }
  }

  return (
    <nav
      ref={containerRef}
      className="absolute top-0 left-0 border-transparent border-solid border-b-2 flex items-center m-0 w-full z-[999] min-h-[80px] justify-start flex-row flex-nowrap"
    >
      <div className="flex flex-wrap items-center justify-between custom_container">
        <Link to="/" className="inline-block w-[50px]">
          <GatsbyImage
            image={sanityHeader.logo.image.asset.gatsbyImageData}
            alt={sanityHeader.logo.alt}
            loading="eager"
            objectFit="contain"
          />
        </Link>

        <button
          className="px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded lg:hidden"
          type="button"
          aria-label="menu-icon"
          onClick={() => setMobileNav(!mobileNav)}
        >
          <span className="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 md:w-10 md:h-10"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        <div
          className={`max-h-[340px] overflow-auto bg-[#11141b] lg:bg-transparent text-center block lg:flex basis-full lg:basis-auto grow items-center 
          transition-all duration-300 ease-linear origin-top ${
            !!mobileNav
              ? "opacity-100 h-auto px-4 py-3"
              : "h-0 lg:h-auto opacity-0 lg:opacity-100"
          }`}
        >
          <ul className="flex flex-col pl-0 mb-0 ml-auto list-none lg:flex-row">
            {sanityHeader.headerLinks.map((link, index) => {
              if (link.wantToNavigate) {
                return (
                  <li key={index} className="list-item">
                    <GatsbyLink
                      className="block text-xs font-medium text-white lg:py-[15px] lg:px-[7px] my-[10px] mx-auto lg:mx-[15px] cursor-pointer"
                      to={`/${link.slug.current}/`}
                      onClick={() => setMobileNav(false)}
                    >
                      {link.linkTitle}
                    </GatsbyLink>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <CustomLink
                      handleClick={() => setMobileNav(false)}
                      to={link.sectionId}
                    >
                      {link.linkTitle}
                    </CustomLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
