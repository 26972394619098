import React from "react";
import "./src/styles/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MouseEffect from "./src/utils/MouseEffect";
import Navbar from "./src/components/Layout/Navbar";
import Footer from "./src/components/Layout/Footer";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <MouseEffect />
      <Navbar />
      {element}
      <Footer />
    </>
  );
};
